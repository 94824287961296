import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';

Swiper.use([Pagination, Autoplay]);

export const projectsSwiper = new Swiper('#projectsSwiper', {
    slidesPerView: 'auto',
    spaceBetween: 20,
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
    },
});

export const servicesSwiper = new Swiper('#servicesSwiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
    },
});