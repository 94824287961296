export const openModal = () => {
    const galleryOfProject = document.querySelector('.gallery-modal');

    galleryOfProject.style.display = 'flex';
    document.documentElement.style.overflow = 'hidden';
}

export const closeModal = (modal) => {
    modal.style.display = 'none';
    document.documentElement.style.overflowX = 'hidden';
    document.documentElement.style.overflowY = 'auto';
}

export function handleFormSuccess() {
    form.reset();
    openDefaultModal('Thanks for the feedback, wait for a response');
}

function openDefaultModal(message) {
    const defaultModal = document.querySelector('.default-modal');
    const modalText = document.querySelector('.default-modal__text');

    defaultModal.style.display = 'flex';

    modalText.textContent = message;

}