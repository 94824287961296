import lottie from "lottie-web";
import animationData from '../assets/lottie/schemeLottie.json';

export function loadLottieAnimation(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const container = entry.target;

            lottie.loadAnimation({
                container: container,
                animationData: animationData,
                renderer: 'svg',
                loop: false,
                autoplay: true
            });

        // Отключаем наблюдение после загрузки анимации
            observer.unobserve(container);
        }
    });
}
