export function checkVisibility() {
    const elements = document.querySelectorAll('.fade-in');
    const windowHeight = window.innerHeight;

    elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top <= windowHeight - 350) {
            element.classList.add('is-visible');
        }
    });
}