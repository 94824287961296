import { updateGallerySwipers } from "./gallerySwipers.js";

const createGalleryItem = (image) => {
    const imageItem = document.createElement('div');
    imageItem.classList.add('swiper-slide');

    const img = document.createElement('img');
    img.setAttribute('src', image);
    img.setAttribute('alt', '');

    imageItem.appendChild(img);

    return imageItem;
}

export const handleClickToProject = (item) => {
    const galleryOfProject = document.querySelector('.gallery-modal');
    const galleryModalGallery = document.querySelector('.gallery-photos');
    const galleryModalGalleryMini = document.querySelector('.gallery-photos-mini');
    const galleryModalName = document.querySelector('.gallery-modal__name');
    const galleryModalDescription = document.querySelector('.gallery-modal__description');

    galleryOfProject.style.display = 'flex';
    document.documentElement.style.overflow = 'hidden';

    galleryModalGallery.innerHTML = '';
    galleryModalGalleryMini.innerHTML = '';
    updateGallerySwipers();

    galleryModalName.textContent = item.name;
    galleryModalDescription.textContent = item.description;

    item.gallery.forEach((image) => {
        const imageItem = createGalleryItem(image);
        const imageItem2 = createGalleryItem(image);

        galleryModalGallery.appendChild(imageItem);
        galleryModalGalleryMini.appendChild(imageItem2);
    });
}
