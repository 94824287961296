import project1Example1 from '../assets/img/project1Example1.webp';
import project1Example2 from '../assets/img/project1Example2.webp';
import project1Example3 from '../assets/img/project1Example3.webp';
import project1Example4 from '../assets/img/project1Example4.webp';
import project1Example5 from '../assets/img/project1Example5.webp';
import project1Example6 from '../assets/img/project1Example6.webp';
import project1Example7 from '../assets/img/project1Example7.webp';
import project1Example8 from '../assets/img/project1Example8.webp';
import project1Example9 from '../assets/img/project1Example9.webp';

import project2Example1 from '../assets/img/project2Example1.webp';
import project2Example2 from '../assets/img/project2Example2.webp';
import project2Example3 from '../assets/img/project2Example3.webp';
import project2Example4 from '../assets/img/project2Example4.webp';
import project2Example5 from '../assets/img/project2Example5.webp';
import project2Example6 from '../assets/img/project2Example6.webp';

import project3Example1 from '../assets/img/project3Example1.webp';
import project3Example2 from '../assets/img/project3Example2.webp';
import project3Example3 from '../assets/img/project3Example3.webp';

import project4Example1 from '../assets/img/project4Example1.webp';
import project4Example2 from '../assets/img/project4Example2.webp';
import project4Example3 from '../assets/img/project4Example3.webp';
import project4Example4 from '../assets/img/project4Example4.webp';
import project4Example5 from '../assets/img/project4Example5.webp';
import project4Example6 from '../assets/img/project4Example6.webp';
import project4Example7 from '../assets/img/project4Example7.webp';

import project5Example1 from '../assets/img/project5Example1.webp';
import project5Example2 from '../assets/img/project5Example2.webp';
import project5Example3 from '../assets/img/project5Example3.webp';
import project5Example4 from '../assets/img/project5Example4.webp';

import project6Example1 from '../assets/img/project6Example1.webp';
import project6Example2 from '../assets/img/project6Example2.webp';
import project6Example3 from '../assets/img/project6Example3.webp';
import project6Example4 from '../assets/img/project6Example4.webp';
import project6Example5 from '../assets/img/project6Example5.webp';

export const projects = [
    { 
        id: 0, 
        name: 'LOD400', 
        type: 'Revit', 
        description: 'Architecture, Electrics, Fire extinguishing system, HVAC, Structure',
        gallery: [project1Example1, project1Example2, project1Example3, project1Example4, project1Example5, project1Example6, project1Example7, project1Example8, project1Example9]
    },
    { 
        id: 1, 
        name: 'LOD350', 
        type: 'AutoCAD', 
        description: '3D visualisation of interiors and exteriors in 3D Max. 3D visualisation of interiors and exteriors',
        gallery: [project2Example1, project2Example2, project2Example3, project2Example4, project2Example5, project2Example6]
    },
    { 
        id: 2, 
        name: 'MEP - LOD300', 
        type: 'Revit', 
        description: 'Architecture, Electrics, Fire extinguishing system, Equipment',
        gallery: [project3Example1, project3Example2, project3Example3]
    },
    { 
        id: 3, 
        name: 'LOD400', 
        type: 'Revit', 
        description: 'Architecture, Electrics, Fire extinguishing system, HVAC, Structure',
        gallery: [project4Example1, project4Example2, project4Example3, project4Example4, project4Example5, project4Example6, project4Example7]
    },
    { 
        id: 4, 
        name: 'LEGO1', 
        type: 'Revit', 
        description: 'Description',
        gallery: [project5Example1, project5Example2, project5Example3, project5Example4]
    },
    { 
        id: 5, 
        name: 'LEGO4', 
        type: 'Revit', 
        description: 'Description',
        gallery: [project6Example1, project6Example2, project6Example3, project6Example4, project6Example5]
    },
];