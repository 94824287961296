import Swiper from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';

Swiper.use([Navigation, Thumbs]);

let gallerySwiperDecore = null;

let gallerySwiperMain = null;

export function initGallerySwipers() {
    gallerySwiperDecore = new Swiper("#gallerySwiperDecore", {
        spaceBetween: 10,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesProgress: true,
    });
    gallerySwiperMain = new Swiper("#gallerySwiperMain", {
        spaceBetween: 10,
        swiperPerSlide: 1,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: gallerySwiperDecore,
        },
    });
}

export function updateGallerySwipers() {
    if (gallerySwiperMain !== null) {
        gallerySwiperMain.destroy(true, true);
        gallerySwiperDecore.destroy(true, true);
    }
    initGallerySwipers();
}