import { handleClickToProject } from "./projectHandler.js";

export function createProjectCard(card) {
    const listItem = document.createElement('li');
    listItem.classList.add('projects__card', 'swiper-slide');

    const cardTitles = document.createElement('div');
    cardTitles.classList.add('projects__card-titles');

    const cardName = document.createElement('p');
    cardName.classList.add('projects__card-name');
    cardName.textContent = card.name;
    cardTitles.appendChild(cardName);

    const cardType = document.createElement('div');
    cardType.classList.add('projects__card-type');
    cardType.textContent = card.type;
    cardTitles.appendChild(cardType);

    const cardDescription = document.createElement('p');
    cardDescription.classList.add('projects__card-description');
    cardDescription.textContent = card.description;

    const cardPhoto = document.createElement('img');
    cardPhoto.classList.add('projects__card-photo');
    cardPhoto.setAttribute('src', card.gallery[0]);
    cardPhoto.setAttribute('alt', '');

    listItem.appendChild(cardTitles);
    listItem.appendChild(cardDescription);
    listItem.appendChild(cardPhoto);

    listItem.addEventListener('click', () => {
        handleClickToProject(card);
    });

    return listItem;
}