export const applyOverlayMaskButton = (e, buttonContainer) => {
    const overlayEl = e.currentTarget;
    const x = e.pageX - buttonContainer.offsetLeft;
    const y = e.pageY - buttonContainer.offsetTop;

    overlayEl.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
};

const observerButton = new ResizeObserver((entries, overlay) => {
    entries.forEach((entry) => {
        let width = entry.borderBoxSize[0].inlineSize;
        let height = entry.borderBoxSize[0].blockSize;
        overlay.children.style.width = `${width}px`;
        overlay.children.style.height = `${height}px`;
    });
});

export const initOverlayButton = (cardEl, overlay) => {
    const overlayCard = document.createElement("div");
    overlayCard.classList.add("button");
    overlay.append(overlayCard);
    observerButton.observe(cardEl, overlay);
};