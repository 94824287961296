const servicesCardsContainer = document.querySelector(".services__list-wrap");
const servicesCards = Array.from(document.querySelectorAll(".welcome__btn"));
const overlay = document.querySelector(".overlay");

export const applyOverlayMask = (e) => {
    const overlayEl = e.currentTarget;
    const x = e.pageX - servicesCardsContainer.offsetLeft;
    const y = e.pageY - servicesCardsContainer.offsetTop;

    overlayEl.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
};

const observer = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
        const cardIndex = servicesCards.indexOf(entry.target);
        let width = entry.borderBoxSize[0].inlineSize;
        let height = entry.borderBoxSize[0].blockSize;

        if (cardIndex >= 0) {
        overlay.children[cardIndex].style.width = `${width}px`;
        overlay.children[cardIndex].style.height = `${height}px`;
        }
    });
});

export const initOverlayCard = (cardEl) => {
    const overlayCard = document.createElement("div");
    overlayCard.classList.add("services__card");
    overlay.append(overlayCard);
    observer.observe(cardEl);
};