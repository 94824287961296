import { applyOverlayMaskButton, initOverlayButton } from "./buttonHoverAnimation.js";
import { createProjectCard } from "./createProjectCard.js";
import { applyOverlayMask, initOverlayCard } from "./hoverAnimation.js";
import { loadLottieAnimation } from "./loadLottieAnimation.js";
import { closeModal, handleFormSuccess } from "./modal.js";
import { initializeProjects } from "./projects.js";
import { projects } from "./projectsArray.js";
import { projectsSwiper, servicesSwiper } from "./swipers.js";
import { checkVisibility } from "./visibilityAnimation.js";
import 'swiper/swiper-bundle.min.css';

const galleryOfProject = document.querySelector('.gallery-modal');
const galleryClose = document.querySelector('.gallery-modal__close');
const projectsList = document.querySelector('.projects__list');
const galleryContent = document.querySelector('.gallery-modal__content');
const burgerMenu = document.querySelector('.header__burg');
const menu = document.querySelector('.header__menu');
const servicesCards = Array.from(document.querySelectorAll(".services__card-animation"));
const servicesCardsContainer = document.querySelector(".services__list-wrap");
const form = document.getElementById('form');
const buttonContainer = document.querySelectorAll('.button-wrap');
const button = document.querySelectorAll('.button');
const overlay = document.querySelectorAll(".button-overlay");
const lottieContainer = document.getElementById('lottie-container');
const defaultModalClose = document.querySelector('.default-modal__close');
const defaultModal = document.querySelector('.default-modal');
const defaultModalContent = document.querySelector('.default-modal__content');

galleryClose.addEventListener('click', () => closeModal(galleryOfProject));
galleryOfProject.addEventListener('click', () => closeModal(galleryOfProject));
galleryContent.addEventListener('click', (event) => event.stopPropagation());
burgerMenu.addEventListener('click', function() {
    this.classList.toggle('header__burg--active');
    menu.classList.toggle('header__menu--active');
});
window.addEventListener('scroll', checkVisibility);

defaultModalClose.addEventListener('click', () => closeModal(defaultModal));
defaultModal.addEventListener('click', () => closeModal(defaultModal));
defaultModalContent.addEventListener('click', (event) => event.stopPropagation())

if(window.innerWidth >= 1140) {
    servicesCards.forEach(initOverlayCard);
    servicesCardsContainer.addEventListener("pointermove", applyOverlayMask);

    button.forEach((btn, index) => {
        initOverlayButton(btn, overlay[index]);
    })
    buttonContainer.forEach((container) => {
        container.addEventListener("pointermove", (e) => applyOverlayMaskButton(e, container));
    })
}

checkVisibility();
initializeProjects();

projects.forEach((card) => {
    const projectItem = createProjectCard(card);
    projectsList.appendChild(projectItem);
});

/* async function sendData() {
    const formData = new FormData(form);
    try {
        const response = await fetch("#", {
            method: "POST",
            body: formData,
        });
        await response.json();
        handleFormSuccess();
    } catch (e) {
        console.error(e);
    }
} */

form.addEventListener("submit", (event) => {
    event.preventDefault();
    handleFormSuccess();
    /* sendData(); */
});

const observer = new IntersectionObserver(loadLottieAnimation, { threshold: 0 });
observer.observe(lottieContainer);